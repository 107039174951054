<template>
  <b-sidebar
    id="add-edit-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Edit Product</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updatedata)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Serial"
            rules="required"
          >
            <b-form-group label="Serial" label-for="Serial">
              <b-form-input
                id="proSerial"
                v-model="formData.proSerial"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Model"
            rules="required"
          >
            <b-form-group
              label="Model"
              label-for="model"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="formData.model"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="modelOptions"
                :reduce="(val) => val.value"
                input-id="model"
                @input="ModelChange"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="modelOption"
          >
            <b-form-group label="Options" label-for="modelOption" class="grp-model-opt">
              <v-select
                v-model="formData.modelOptions"
                :options="proOptOptions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(val) => val.value"
                input-id="modelOption"
                multiple
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="API Key"
            rules="required"
          >
            <b-form-group label="API Key" label-for="API Key">
              <b-form-input
                id="apiKey"
                v-model="formData.apiKey"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Latitude"
            rules="required"
          >
            <b-form-group
              label="Latitude"
              label-for="Latitude"
              description="eg. 13.2955451"
            >
              <b-form-input
                id="latitude"
                v-model="formData.latitude"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="ตัวอย่าง : 13.2955451"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Longitude"
            rules="required"
          >
            <b-form-group
              label="Longitude"
              label-for="Longitude"
              description="eg. 100.946805"
            >
              <b-form-input
                id="longitude"
                v-model="formData.longitude"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="ตัวอย่าง : 100.946805"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Date Product"
            rules="required"
          >
            <b-form-group label="Date Product" label-for="Date Product">
              <b-form-datepicker
                id="proDate"
                v-model="formData.proDate"
                class="form-control mb-1"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Location Name"
          >
            <b-form-group
              label="Location Name"
              label-for="Location Name"
            >
              <b-form-input
                id="locationName"
                v-model="formData.locationName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="address">
            <b-form-group label="Address" label-for="address">
              <b-form-textarea
                id="address"
                rows="3"
                v-model="formData.address"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="proNote">
            <b-form-group label="Note" label-for="proNote">
              <b-form-textarea
                id="proNote"
                rows="3"
                v-model="formData.proNote"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BFormDatepicker,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import productService from "@/services/productService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditSidebarActive",
    event: "update:is-edit-sidebar-active",
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    modelOptions: {
      type: Array,
      required: true,
    },
    proOptOptions: {
      type: Array,
      required: true,
    },
    formData: {},
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation();

    return {
      refFormObserver,
      getValidationState,
    };
  },
  watch: {
    formData() {
      this.formData.modelOptions = JSON.parse(this.formData.modelOptions)
    }
  },
  computed: {},
  methods: {
    ModelChange(value) {
      if (value == 'TKA') {
        this.formData.modelOptions = ['al','temp','blind','quiz'];
      } else if(value == 'TKD') {
        this.formData.modelOptions = ['al','bp','temp','sp_foot','sp_hand','blind','quiz'];
      } else if(value == 'TKW') {
        this.formData.modelOptions = ['al','bp','temp','sp_foot','push_sp','blind','quiz'];
      } 
    },
    updatedata() {
      try {
        productService
          .updatedata(this.formData.proId, this.formData)

          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "อัพเดทข้อมูลไม่สำเร็จ",
                text: "Error!",
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "อัพเดทข้อมูลสำเร็จ",
                text: "Update data successfully!",
                timer: 3000,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-edit-sidebar-active", false);
            }
          })
          .catch(() => {});
      } catch (e) {
        //console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-edit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.grp-model-opt .vs__selected {
    background-color: #28c76f;
    border: 0 solid #28c76f;
}
</style>
